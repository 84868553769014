import React from 'react';
import classNames from 'classnames';
import { CardBlobOverlay } from './svgs';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import {
  getCardConfigurations,
  getCurrentClass,
  getOverlayTextStyles,
  getOverlayWrapperStyles,
  getShapeStyles,
  getTextContainerStyles,
} from './utils';
import { DEFAULT_COLLECTION_CARD_IMAGE } from '@/components/WidgetMaker/WidgetDnD/WidgetProperties/ListPresentation/ListPresentationConfig';
import { twMerge } from 'tailwind-merge';
import { getButtonVariantsData } from '../Carousel/utils';
import { btnActionHandler } from '../../ModernTheme/utils/btnUtils';
import { BUTTON_TYPE } from '@/components/WidgetMaker/utils/buttonConstant';
import router from 'next/router';
import { PATH, getRoute } from 'src/utils/routes';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { useSSRSelector } from 'src/redux';

interface ICardFrameCollections {
  oneGridCellGeometry: { width: number; height: number };
  itemConfig?: any;
  itemIndex?: number;
  cardFrameConfig: any;
  openInNewTab?: boolean;
  containerTracks?: { numColumns: number; numRows: number };
  isMobile?: boolean;
  customStyle?: any;
  onCollectionClick?: (...args: any[]) => void;
  isCategory?: boolean;
}

export default function CardFrameForCollection(props: ICardFrameCollections) {
  const {
    oneGridCellGeometry,
    cardFrameConfig,
    itemConfig,
    itemIndex,
    openInNewTab,
    containerTracks,
    isMobile,
    customStyle,
    isCategory,
    onCollectionClick,
  } = props;

  const collectionName = itemConfig?.data?.name || '';
  const store = useSSRSelector((state) => state.storeReducer.store);

  const cardShape: string = cardFrameConfig?.cardShape;
  const shouldRenderBlob = false; // cardShape === 'blob';
  // If any element is not textual
  const {
    isNonTextualElementPresent,
    isButtonLayout,
    isTextNameBelow,
    isCollectionNameVisible,
  } = getCardConfigurations(cardFrameConfig);
  // When in mobile layout num of columns is 2
  const mobileExceptions = {
    oneColumn: isMobile && containerTracks.numColumns === 1,
    twoColumn: isMobile && containerTracks.numColumns === 2,
  };

  const shapeStyleCalculated = getShapeStyles(cardShape, oneGridCellGeometry.width);
  const shapeStyle = shapeStyleCalculated[itemIndex % shapeStyleCalculated?.length];

  const overlayWrapperStyles = getOverlayWrapperStyles(cardFrameConfig, isTextNameBelow);

  function handleItemClick(e) {
    if (isCategory) {
      const redirectUrl = getRoute(
        `${
          isPLPRequiredSystem(store?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
        }?cid=${itemConfig?.id || 0}`,
        store?.store_info?.domain
      );
      if (openInNewTab) {
        window.open(redirectUrl, '_blank');
      } else {
        router.push(redirectUrl, undefined, { shallow: true });
      }
    } else {
      onCollectionClick(itemConfig?.type, itemConfig?.data, e, openInNewTab);
    }
  }

  const onButtonClick = (e, btnData) => {
    if (btnData?.actions?.type) btnActionHandler(btnData?.actions, () => {});
    else handleItemClick(e);
  };

  const _RenderCollectionName = () =>
    cardFrameConfig?.contentConfig?.textConfig?.appearanceButton?.map(
      (buttonData, btnIndex) => {
        const overlayButtonStyles = getTextContainerStyles(cardFrameConfig);
        const overlayTextStyles: any = getOverlayTextStyles(cardFrameConfig, buttonData);
        const buttonVariant = getButtonVariantsData(buttonData, false);
        const selectedButtonType = buttonVariant[buttonData.type];
        const doesNameContainsSpace = collectionName?.indexOf(' ') >= 0;
        const isBtnSolid = buttonData.type === BUTTON_TYPE.SOLID;
        const isUnderlineAnimation = buttonData?.buttonAnimationConfig?.classKey
          ?.toLowerCase()
          ?.includes('underline');
        return buttonData.isShow ? (
          <CustomizedButton
            key={btnIndex}
            size={cardFrameConfig?.contentConfig?.textConfig?.textSize}
            btnType={buttonData.type}
            buttonData={buttonData}
            selectedButtonData={selectedButtonType}
            variant={selectedButtonType.variant}
            onClick={(e) => onButtonClick(e, buttonData)}
            className={twMerge(
              'tw-pointer-events-auto !tw-max-w-[100%] !tw-whitespace-nowrap !tw-rounded-none !tw-font-normal',
              buttonData?.type === 'textual'
                ? '!tw-w-max'
                : '!tw-w-[100%] !tw-px-[10px] !tw-py-[10px]',
              isBtnSolid ? ' !tw-border-none' : '',
              overlayButtonStyles.className,
              !buttonData?.buttonAnimationConfig?.classKey
                ? '[&_p]:before:!tw-hidden'
                : buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey]
            )}
            textWrapperClass={isUnderlineAnimation && '!tw-mx-0 !tw-mb-[2.2px] !tw-mt-0'}
          >
            <p
              className={classNames(
                'tw-m-0 tw-line-clamp-2 tw-max-w-[100%] tw-text-ellipsis tw-whitespace-break-spaces',
                doesNameContainsSpace ? 'tw-break-words' : 'tw-break-all'
              )}
              style={{ ...overlayTextStyles, lineHeight: 1.2 }}
            >
              {collectionName}
            </p>
          </CustomizedButton>
        ) : null;
      }
    );

  const _RenderButton = () =>
    cardFrameConfig?.contentConfig?.buttonConfig
      ?.filter((btn) => btn.isShow)
      ?.map((buttonData, btnIndex) => {
        const overlayTextStyles: any = getOverlayTextStyles(cardFrameConfig, buttonData);
        const buttonVariant = getButtonVariantsData(buttonData, false);
        const selectedButtonType = buttonVariant[buttonData.type];
        const isBtnSolid = buttonData.type === BUTTON_TYPE.SOLID;
        return buttonData.isShow ? (
          <CustomizedButton
            key={btnIndex}
            // size={cardFrameConfig?.contentConfig?.textConfig?.textSize}
            btnType={buttonData.type}
            buttonData={buttonData}
            selectedButtonData={selectedButtonType}
            variant={selectedButtonType.variant}
            onClick={(e) => handleItemClick(e)}
            className={twMerge(
              'tw-pointer-events-auto !tw-w-min !tw-max-w-[100%] !tw-whitespace-nowrap !tw-rounded-none !tw-font-normal',
              mobileExceptions.twoColumn ? '!tw-mt-[12px]' : '!tw-mt-[16px]',
              !buttonData?.buttonAnimationConfig?.classKey
                ? '[&_p]:before:!tw-hidden'
                : buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey],
              isBtnSolid ? ' !tw-border-none ' : '',
              buttonData?.type !== 'textual' ? '!tw-px-[10px] !tw-py-[10px]' : ''
            )}
          >
            <p
              className={classNames(
                'tw-m-0 tw-font-medium',
                mobileExceptions.twoColumn ? '!tw-text-[12px]' : '!tw-text-[14px]'
              )}
              style={overlayTextStyles || {}}
            >
              {buttonData?.text}
            </p>
          </CustomizedButton>
        ) : null;
      });

  return (
    <div
      className={'tw-flex tw-cursor-pointer tw-select-none tw-flex-col tw-items-center'}
      style={customStyle || {}}
      onClick={(e) => handleItemClick(e)}
    >
      <div
        className={classNames(
          'tw-relative tw-flex-shrink-0 tw-overflow-hidden',
          shapeStyle.className
        )}
        style={{
          width: `${oneGridCellGeometry.width}px`,
          height: `${oneGridCellGeometry.height}px`,
          ...(shapeStyle.style || {}),
        }}
      >
        {!shouldRenderBlob ? (
          <img
            src={itemConfig?.data?.image_url || DEFAULT_COLLECTION_CARD_IMAGE}
            className={classNames(
              'tw-absolute tw-h-[100%] tw-w-[100%] tw-object-cover tw-transition-all tw-duration-500',
              contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverEffect)],
              shapeStyle.className
            )}
            loading="lazy"
            style={shapeStyle.style || {}}
          />
        ) : (
          <CardBlobOverlay
            className="tw-absolute tw-inset-0 tw-h-[100%] tw-w-[100%]"
            imageSrc={itemConfig?.data?.image_url || DEFAULT_COLLECTION_CARD_IMAGE}
            width={oneGridCellGeometry.width}
          />
        )}
        {cardFrameConfig.contentConfig.textConfig.type === 'overlay' && (
          <div
            className={classNames(
              'shadowOverlay imgWrapperGlobalStyle globalImgStyleConsumer tw-pointer-events-none tw-absolute tw-inset-0 tw-flex tw-flex-col ',
              shapeStyle.className,
              mobileExceptions.twoColumn && isButtonLayout
                ? 'tw-p-[10px]'
                : isButtonLayout
                  ? 'tw-p-[16px]'
                  : isNonTextualElementPresent
                    ? 'tw-p-[12px]'
                    : 'tw-p-[20px]'
            )}
            style={{ ...overlayWrapperStyles }}
          >
            {_RenderCollectionName()}
            {_RenderButton()}
          </div>
        )}
      </div>
      {isTextNameBelow && isCollectionNameVisible && (
        <div
          className="tw-mt-[16px] tw-flex tw-w-[100%] tw-flex-col"
          style={overlayWrapperStyles}
        >
          {_RenderCollectionName()}
        </div>
      )}
    </div>
  );
}
