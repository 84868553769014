export function CardBlobOverlay(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
      <clipPath id="clip-path">
        <path
          fill="transparent"
          d="M150.2 63.2c15.7 16.5 29.9 37.4 26.6 55.4-3.2 17.9-24 33-46.6 43.7-22.7 10.8-47.2 17.4-64.8 9.1-17.6-8.3-28.3-31.5-33.7-55.7-5.4-24.1-5.5-49.4 6.8-65.1 12.3-15.7 36.9-21.8 57.8-18.9 20.9 2.9 38.2 15 53.9 31.5z"
        />
      </clipPath>
      <image
        clip-path="url(#clip-path)"
        xlinkHref={props.imageSrc}
        height="100%"
        width="100%"
      />
    </svg>
  );
}
